



























import {Component, Prop, Vue} from "vue-property-decorator";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import DocumentService from "@/services/DocumentService";
import CompanyDTO from "@/dto/company/CompanyDTO";
import Workspaces from "@/state/Workspaces";
import ReceiptDTO from "@/dto/ReceiptDTO";
import {namespace} from "vuex-class";
import PortalInput from "@/components/common/PortalInput.vue";

const AppModule = namespace("App");

@Component({
  components: {PortalInput, FileUpload}
})



export default class ReceiptsCompany extends Vue {

  @AppModule.Action
  private startLoading!: () => void


  @AppModule.Action
  private stopLoading!: () => void


  @Prop()
  private onFilesUploaded!: () => void


  private message = "";

  private company: CompanyDTO | null = null;

  private successful = true;

  private receipts: ReceiptDTO[] = [];

  private pageNumber = 1;

  private totalPages = 1;


  private changePage(page: number): void {
    this.pageNumber = page;
    this.getFilesPage();
  }

  mounted() {
    this.getFilesPage();
  }

  get getId() {
    return Workspaces.getCurrent.id;
  }

  getFilesPage() {
    this.startLoading()
    DocumentService.getReceiptsPage(this.pageNumber, 20, null, this.getId).then(
        response => {

          this.receipts = response.data.content;
          this.totalPages = response.data.totalPages;
          this.stopLoading()
        },
        error => {
          this.stopLoading()
          console.error("Error fetching receipts:", error);
        }
    )
  }


  onUploaded(files: FileMetaDTO[]) {
    this.startLoading()
    DocumentService.saveReceipt(this.getId, files.map(f => f.id)).then(
        ok => {
          this.getFilesPage()
          this.stopLoading()
        },
        error => {
          console.log(error.message)
        }
    )
  }



}
